import "../assets/styles/twoColsSection.scss";

import image from "../assets/icons/LigneRose.svg";

import Title from "../components/title";
import Cta from "../components/cta";

import Section from "../components/intersectionObserver";

export default function TwoColSection() {
  return (
    <div className="two-col-section" id="mission">

      <div className="container">
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div className="">
              <Section>
                <Title
                  text="<span class='c-green'>Notre porte en&nbsp;</span><span class='c-green'> ouvre d'autres</span>"
                 
                  headerType="h2"
                ></Title>
              </Section>
              <Section>
                <p>
                Passer la porte du CJE, c’est t’ouvrir à un monde de possibilités! École, emploi, projets, finances, intégration, peu importe tes questions on est là pour t’aider à trouver les réponses.
                  <b>
                    &nbsp;En plus, notre accompagnement est confidentiel, personnalisé
                    et gratuit!
                  </b>
                </p>
              </Section><br/>
              <Section>
                {" "}
                <Cta
                  colorScheme="blueBtn"
                  text="Ça m'intéresse"
                  url={"#contact"}
                ></Cta>
              </Section>
            </div>
          </div>
        </div>
      </div>
      <Section>
        {" "}
        <img src={image} alt="" className="absoluteImage" />
      </Section>
    </div>
  );
}
