import "../assets/styles/faq.scss";
import { useState, useEffect } from "react";
import image from "../assets/images/PhotoQuestions.png";
import Accordion from "../components/accordion";
import Title from "../components/title";
import Section from "../components/intersectionObserver";
export default function Faq() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="faq" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            {width > 767 && (
              <img src={image} alt="" className="absoluteImage" />
            )}
            <span>&nbsp;</span>
          </div>
          <div className="col-md-8">
            {" "}
            <Section>
              {" "}
              <Title
                text="Questions fréquentes"
                headerType="h2"
                color="c-green"
              ></Title>
            </Section>
            <div id="accordion">
              <Accordion
                id="One"
                isOpen={"true"}
                q="J’ai une entrevue et je ne sais pas comment me préparer, pouvez-vous m’aider?"
                a="Une entrevue est l’occasion de convaincre l’employeur que tu es la
              bonne personne pour le poste...
              <b>et de valider que c’est bien le milieu que TU recherches!</b>
              Nous pouvons t’accompagner pour t’aider à bien te préparer et
              faire de ton entrevue une expérience gagnante."
              ></Accordion>
              <Accordion
                id="Two"
                isOpen={"false"}
                q="À quoi ça sert un budget?"
                a="Un budget c’est un outil qui te permet de <b>comprendre exactement ce qui entre dans tes poches et ce qui en sort</b>. Ça demande un peu d’organisation au début, mais tu pourras mieux établir tes priorités, te fixer des objectifs… et surtout, ça t’aide à diminuer ton stress! Mieux gérer ton argent, c’est pas si compliqué et c’est payant!"
              ></Accordion>
              <Accordion
                id="Three"
                isOpen={"false"}
                q="Je veux aller à l’école, mais je ne sais pas dans quel domaine... lequel choisir?"
                a="C’est vrai que choisir un domaine d’étude peut être intimidant, mais le CJE est là pour t’aider! Nos services d’accompagnement te permettent d’explorer différents domaines <b>afin de trouver celui qui te ressemble vraiment</b>. Ensemble, nous pouvons identifier tes aspirations, tes valeurs, tes besoins en matière d’emploi et établir un plan de match pour ton retour aux études."
              ></Accordion>
              <Accordion
                id="Four"
                isOpen={"false"}
                q="J'ai envie de me starter une business, par où commencer?"
                a="Devenir entrepreneur, c’est le rêve de bien des gens, mais c’est aussi une voie remplie de défis! Le CJE est là pour t’aider à valider si elle correspond à tes attentes et aspirations. Nous pouvons t’aider à bien définir ton projet d’entreprise et <b>identifier les ressources et les stratégies qui te seront utiles pour commencer à le bâtir</b>. Passe nous voir!"
              ></Accordion>
              <Accordion
                id="Five"
                isOpen={"false"}
                q="J’ai le goût de m’impliquer, de faire une différence dans ma communauté, comment&nbsp;faire?"
                a="Tout ce dont t’as besoin, c’est d’une idée! Viens nous parler de ce qui te touche de ce qui te fait vibrer, et on peut t’accompagner pour mettre sur pied <b>un projet qui aura un impact durable dans ton milieu!</b> En panne d’inspiration? On peut aussi t’aider! On a différents projets déjà en place auxquels tu peux prendre part pour apprendre à mieux te connaître, expérimenter et faire une différence."
              ></Accordion>
              <Accordion
                id="Six"
                isOpen={"false"}
                q="Qu’est-ce que je dois apporter avec moi lors de ma première visite au CJEL?"
                a="Au CJEL, on t’accueille comme tu es! Afin de bien répondre à tes besoins, on t’encourage quand même à apporter <b>ton numéro d’assurance sociale et ton numéro d’assurance maladie</b>, dont on va avoir besoin pour t’inscrire à certains de nos services."
              ></Accordion>
              <Accordion
                id="Seven"
                isOpen={"false"}
                q="En cas d’invasion zombie, qu’est-ce que le CJEL peut m’apporter?"
                a="Invasion ou pas, le CJEL offrira toujours un safe space, une équipe qui t’accueille à bras ouverts, une salle de bain privé, un café, et bien plus encore! Même si tu vis de l’anxiété ou que tu as le sentiment que rien ne va plus, n’hésite pas à franchir notre porte."
              ></Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
