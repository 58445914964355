import "../assets/styles/titles.scss";

export default function Title({headerType, color, text, ref}) {

  return headerType === "h1" ? (
    <h1 className={color} ref={ref} dangerouslySetInnerHTML={{__html: text}}></h1>
  ) : headerType === "h2" ? (
    <h2 className={color} ref={ref} dangerouslySetInnerHTML={{__html: text}}></h2>
  ) : headerType === "h3" ? (
    <h3 className={color} ref={ref} dangerouslySetInnerHTML={{__html: text}}></h3>
  ) : headerType === "h4" ? (
    <h4 className={color} ref={ref} dangerouslySetInnerHTML={{__html: text}}></h4>
  ) : headerType === "h5" ? (
    <h5 className={color} ref={ref} dangerouslySetInnerHTML={{__html: text}}></h5>
  ) : (
    <p className={color} ref={ref} dangerouslySetInnerHTML={{__html: text}}></p>
  );
}


