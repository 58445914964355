import "../assets/styles/hero.scss";

import imageOne from "../assets/images/ProfilOrange@2x.png";
import imageTwo from "../assets/images/ProfilVert@2x.png";
import imageThree from "../assets/images/ProfilViolet@2x.png";

export default function Hero() {
  return (
    <div className="hero">
      <div className="heroContent container">
        <div className="row">
          <div className="col-md-6">
            <p>
              Nous t'aidons gratuitement à <br />
              <div className="roller">
                <span id="rolltext">
                trouver l’emploi de<br/>tes rêves
                  <br />
                  choisir le programme d’études fait pour toi
                  <br />
                  préparer et réaliser tes&nbsp;projets
                  <br />
                </span>
              </div>
            </p>
          </div>
          <div className="col-md-6">
            <div className="featuredImages">
              <div className="row">
                <div className="col">
                  <img src={imageOne} alt="" />
                </div>
                <div className="col">
                  <img src={imageThree} alt="" />
                </div>
                <div className="col">
                  <img src={imageTwo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
