import "../assets/styles/services.scss";
import "../assets/styles/general.scss";
import CardItem from "../components/cardItem";
import Cta from "../components/cta";
import Title from "../components/title";

import emploi from "../assets/icons/ServiceEmploi.svg";
import education from "../assets/icons/ServiceEducation.svg";
import habitudes from "../assets/icons/ServiceHabitudes.svg";
import finances from "../assets/icons/ServiceFinances.svg";
import citoyen from "../assets/icons/ServiceCitoyen.svg";
import communaute from "../assets/icons/ServiceCommunaute.svg";
import entreprenariat from "../assets/icons/ServiceEntreprenariat.svg";
import retour from "../assets/icons/ServiceRetour.svg";

import Section from "../components/intersectionObserver";

export default function Services() {
  return (
    <div className="services" id="services">
      <div className="container">
        <Section>
          {" "}
          <Title text="Nos services" headerType="h2" color="c-green"></Title>
        </Section>
        <div className="row">
          <div className="col-md-3 col-6">
            <CardItem
              image={emploi}
              alt=""
              service="Services liés à l'emploi"
              details="(accompagnement, exploration, préparation, recherche d’emploi, chômage, normes, conditions de travail, etc.)"
            ></CardItem>
          </div>
          <div className="col-md-3 col-6">
            <CardItem
              image={education}
              alt=""
              service="Services liés à l'éducation"
              details="(retour aux études, informations scolaires et professionnelles, raccrochage, options de financement, etc.)"
            ></CardItem>
          </div>
          <div className="col-md-3 col-6">
            <CardItem
              image={habitudes}
              alt=""
              service="Services liés aux habitudes de vie"
              details="(logement, socialisation, transport, gestion du stress, santé, relations, etc.)"
            ></CardItem>
          </div>
          <div className="col-md-3 col-6">
            <CardItem
              image={finances}
              alt=""
              service="Services liés aux finances"
              details="(budget, crédit, épargne, achats, cotisations, etc.)"
            ></CardItem>
          </div>
          <div className="col-md-3 col-6">
            <CardItem
              image={citoyen}
              alt=""
              service="Services liés aux obligations citoyennes"
              details="(impôts, contraventions, documents légaux, etc.)"
            ></CardItem>
          </div>
          <div className="col-md-3 col-6">
            <CardItem
              image={communaute}
              alt=""
              service="Services liés à la communauté"
              details="(implication sociale, création de projets, engagement citoyen, volontariat, etc.)"
            ></CardItem>
          </div>
          <div className="col-md-3 col-6">
            <CardItem
              image={entreprenariat}
              alt=""
              service="Services liés à l’entrepreneuriat"
              details="(profil entrepreneurial, accompagnement, ressources, outils, etc.)"
            ></CardItem>
          </div>
          <div className="col-md-3 col-6">
            <CardItem
              image={retour}
              alt=""
              service="Services liés au retour en région"
              details="(réseautage, qualité de vie, etc.)"
            ></CardItem>
          </div>
        </div>
        <div className="centeredButton">
          <Cta
            colorScheme="purpleBtn"
            text="Parle à notre équipe"
            url={"#contact"}
          ></Cta>
        </div>
      </div>
    </div>
  );
}
