import "../assets/styles/footer.scss";
import logo from "../assets/logos/Logo_Vert-horizontal-gros.png";
import Socials from "../components/socials";

export default function Footer() {
  return (
    <div className="footer container">
      <img src={logo} alt="CJEL Logo" />
      <ul className="socialMedia"><Socials></Socials></ul>
    </div>
  );
}
