import Section from "../components/intersectionObserver";
import "../assets/styles/faq.scss";
import Cta from "./cta";
export default function Accordion({ id, isOpen, q, a }) {
  return (
    <Section>
      <div>
        <div id={`heading${id}`}>
          <h5 className="accordionTitle">
            <button
              className="btn btn-link"
              data-toggle="collapse"
              data-target={`#collapse${id}`}
              aria-expanded={isOpen}
              aria-controls={`collapse${id}`}
            >
              {q}
            </button>
          </h5>
        </div>
        <div
          id={`collapse${id}`}
          className={isOpen === "true" ? "collapse show" : "collapse"}
          aria-labelledby={`heading${id}`}
          data-parent="#accordion"
        >
          <div className="card-body">
            <p dangerouslySetInnerHTML={{ __html: a }}/>
            <Cta
              text="Prends contact"
              url="#contact"
              colorScheme="pinkBtn"
            ></Cta>
          </div>
        </div>
      </div>
    </Section>
  );
}
