import Cta from "../components/cta";
import Title from "../components/title";
import Section from "../components/intersectionObserver";
import ReCAPTCHA from "react-google-recaptcha";

import { useFormik } from "formik";

import "../assets/styles/contact.scss";

import React, { useState, useRef } from "react";
import axios from "axios";
// require('dotenv').config();

export default function Contact() {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  let captchaRef = useRef();


  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "Ce champ est requis";
    }

    if (!values.lastName) {
      errors.lastName = "Ce champ est requis";
    }

    if (!values.email) {
      errors.email = "Ce champ est requis";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Courriel invalide";
    }

    if (!values.emailConfirm) {
      errors.emailConfirm = "Ce champ est requis";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailConfirm)
    ) {
      errors.emailConfirm = "Courriel invalide";
    } else if (values.email !== values.emailConfirm) {
      errors.emailConfirm = "Courriels différents";
    }

    if (!values.message) {
      errors.message = "Ce champ est requis";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      emailConfirm: "",
      message: "",
    },
    validate,
    onSubmit: async (values, actions) => {
      const token = await captchaRef.current.executeAsync();
      values.token = token;
      actions.setSubmitting(false);
      actions.resetForm({
        values: {
          firstName: "",
          lastName: "",
          email: "",
          emailConfirm: "",
          message: "",
        },
      });
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/data`, values)
        .then((response) => {
   
          setError(false);
          setSubmitted(true);
        })
        .catch((error) => {
          if(error.response.status === 403){
            setError('Votre soumission à été bloqué car nous soupçonnons qu\'il s\'agit de spam.');
            setSubmitted(false);
          } else {
            setError('Une erreur est survenue lors de l\'envoi du message');
            setSubmitted(false);
          }
          
        });
    },
  });

  return (
    <div className="contact" id="contact">
      <div className="container">
        <Section>
          <Title text="Nous joindre" headerType="h2" color="c-blue2"></Title>
        </Section>
      </div>

        <Section>
          <form onSubmit={formik.handleSubmit} className="form-group">
            <div className="container">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="firstName" id="label-firstName">
                  Prénom*
                </label>
                {formik.touched.firstName && formik.errors.firstName ? (
                  <span className="validation c-orange">
                    {formik.errors.firstName}
                  </span>
                ) : null}
                <input
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="lastName">Nom de famille*</label>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <span className="validation c-orange">
                    {formik.errors.lastName}
                  </span>
                ) : null}
                <input
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="email">Courriel*</label>
                {formik.touched.email && formik.errors.email ? (
                  <span className="validation c-orange">
                    {formik.errors.email}
                  </span>
                ) : null}
                <input
                  className="form-control"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="emailConfirm">Confirmez votre courriel*</label>
                {formik.touched.emailConfirm && formik.errors.emailConfirm ? (
                  <span className="validation c-orange">
                    {formik.errors.emailConfirm}
                  </span>
                ) : null}
                <input
                  className="form-control"
                  id="emailConfirm"
                  name="emailConfirm"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailConfirm}
                />
              </div>
            </div>
            <label htmlFor="message">Votre message*</label>
            {formik.touched.message && formik.errors.message ? (
              <span className="validation c-orange">
                {formik.errors.message}
              </span>
            ) : null}
            <textarea
              className="form-control"
              rows="6"
              id="message"
              name="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
            />
            {submitted === true ? (
              <p>
                <b>Merci de nous avoir contacté!</b>
              </p>
            ) : error && (
              <p>
                <b>{error}</b>
              </p>
            )
            }
            <Cta
              text="Soumettre"
              colorScheme="blueBtn whiteTxt"
              type="submit"
            ></Cta>
            </div>
            <ReCAPTCHA
              
              ref={captchaRef}
              sitekey="6Lf4PZkmAAAAAK_FqQVFwabeu4JjNWJ31RJ9HQ63"
              size="invisible"
              />
          </form>

        </Section>
      </div>
  );
}
