import "../assets/styles/headerMobile.scss";
import logo from "../assets/logos/Logo_Vert-horizontal-gros.png";
import "../assets/styles/hamburger.scss";

import Socials from "../components/socials";

const DesktopHeader = () => {
    function toggleMenu() {
        const navIcon = document.getElementById("nav-icon");
        const links = document.getElementById("links");
        const page = document.getElementsByTagName("html")[0];

        if (navIcon.classList.contains("open")) {
            navIcon.classList.remove("open");
            links.classList.remove("nav-open");
            page.classList.remove("no-scroll");

        }
        else{
            navIcon.classList.add("open");
            links.classList.add("nav-open");
            page.classList.add("no-scroll");

        }
      }
  return (
    <div className="headerMenuMobile">
      <div className="menu-logo container">
      <img src={logo} alt="" className="logo" />
      <div className="hamburger hidden-md" onClick={toggleMenu}>
      <div id="nav-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
     
      </div>
      <nav id="links">
        <ul className="navbar">
          <li>
            <a href="#mission" onClick={toggleMenu}>Notre mission</a>
          </li>
          <li>
            <a href="#services" onClick={toggleMenu}>Nos services</a>
          </li>
          <li>
            <a href="#faq" onClick={toggleMenu}>Questions fréquentes</a>
          </li>
          <li>
            <a href="#contact" onClick={toggleMenu}>Nous joindre</a>
          </li>
          <span className="socialMedia"><Socials></Socials></span>
        </ul>
      </nav>
    </div>
  );
};

export default DesktopHeader;
