import "../assets/styles/header.scss";
import logo from "../assets/logos/Logo_Vert-horizontal-gros.png";
import Socials from "../components/socials";
const DesktopHeader = () => {
  return (
    <div className="headerMenu container">
      <nav>
        <ul className="navbar">
          <li>
            <img src={logo} alt="" className="logo" />
          </li>
          <li>
            <a href="#mission">Notre mission</a>
          </li>
          <li>
            <a href="#services">Nos services</a>
          </li>
          <li>
            <a href="#faq">Questions fréquentes</a>
          </li>
          <li>
            <a href="#contact">Nous joindre</a>
          </li>
          <span className="socialMedia"><Socials></Socials></span>
        </ul>
      </nav>
    </div>
  );
};

export default DesktopHeader;
