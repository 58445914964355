import React, { useState, useEffect } from "react";
import "./App.css";

import DesktopHeader from "./sections/headerMenuDesktop";
import MobileHeader from "./sections/headerMenuMobile";
import Hero from "./sections/hero";
import TwoColSection from "./sections/twoColsSection";
import Services from "./sections/services";
import Faq from "./sections/faq";
import Contact from "./sections/contact";
import Footer from './sections/footer';

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="App">
      {width < 992 ? <MobileHeader /> : <DesktopHeader />}
      <Hero></Hero>

      <TwoColSection></TwoColSection>

      <Services></Services>

      <Faq></Faq>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;
