import "../assets/styles/cardItem.scss";
import Section from "./intersectionObserver";

export default function cardItem({ image, alt, service, details }) {
  return (
    <Section>
      <div className="cardItem">
        <img src={image} alt={alt} />
        <h3>{service}</h3>
        <p>{details}</p>
      </div>
    </Section>
  );
}
