import "../assets/styles/cta.scss";

import arrow from "../assets/icons/FlecheCTA.svg";

export default function Cta({ text, url, colorScheme, type, fct }) {
  const characters = text.replaceAll(" ", String.fromCharCode(160)).split("");

  return (
    <>
      <a href={url} className="waveText">
        <button className={colorScheme} type={type} onClick={fct}>
          {characters.map((char, index) => (
            <span className="char" key={index}>
              {char}
            </span>
          ))}
          <span id="arrowContainer">
            <img id="arrow" src={arrow} alt="" />
          </span>
        </button>
      </a>
    </>
  );
}
